import { useStaticQuery, graphql } from 'gatsby'

export default () => {
  const weddingDetailsData = useStaticQuery(graphql`
    query WeddingDetailsQuery {
      site {
        siteMetadata {
          weddingDetails {
            date
            rsvpDate
          }
        }
      }
    }
  `)

  return weddingDetailsData.site.siteMetadata.weddingDetails
}
