import React from 'react'
import moment from 'moment'
import getWeddingDetails from '../queries/wedding-details'
import Layout from '../components/layout'
import Countdown from '../components/countdown'
import SEO from '../components/seo'
import { Link } from "gatsby"

const IndexPage = () => {
  const weddingDetails = getWeddingDetails();
    
  return <Layout className="home">
    <SEO title="Welcome" />
    <h2 className="our-date calligraphy">{moment(weddingDetails.date).format('MMMM Do YYYY, ha')}</h2>
    <p>
        At last!
    </p>

    <p>
        May 31st 2023 update: One Year Anniversary! About time <Link to="/video/">a video from the day</Link> went online!
    </p>
    
    <p>
        October 2022 update: There are now <Link to="/pictures/">some pictures from the Wedding Day</Link> here on this site!
    </p>
    
    <p>
    </p>
    
    <Countdown date={weddingDetails.date} />
    <h1 className="our-names calligraphy">Esther & Magnus</h1>
  </Layout>
}

export default IndexPage

