import React, { useState, useEffect } from 'react'
import moment from 'moment'

function countdown(date) {
  const currentDate = Date.now()
  const remainingMonths = date.diff(currentDate, 'months')
  const remainingDays = date.subtract(remainingMonths, 'months').diff(currentDate, 'd')
  const remainingHours = date.subtract(remainingDays, 'd').diff(currentDate, 'h')
  const remainingMins = date.subtract(remainingHours, 'h').diff(currentDate, 'm')
  const remainingSecs = date.subtract(remainingMins, 'm').diff(currentDate, 's')

  return {
   remainingMonths,
   remainingDays,
   remainingHours,
   remainingMins,
   remainingSecs,
  }
}

const Countdown = ({ date }) => {
  const countdownBreakdown = countdown(moment(date))
  const [remainingTime, setRemainingTime] = useState(countdownBreakdown)
  const { remainingMonths, remainingDays, remainingHours, remainingMins, remainingSecs } = remainingTime

  useEffect(() => {
    const timer = setInterval(() => setRemainingTime(countdown(moment(date))), 1000)
    return () => {
      clearTimeout(timer);
    }
  },[remainingTime, date])

  return <div className="countdown">
    {!!remainingMonths &&
      <div className="countdown-time-container months">
          <div className="countdown-time-value">{Math.abs(remainingMonths)}</div>
          <div className="countdown-time-label calligraphy">Months</div>
      </div>
    }
      <div className="countdown-time-container days">
      <div className="countdown-time-value">{Math.abs(remainingDays)}</div>
      <div className="countdown-time-label calligraphy">Days</div>
    </div>
    <div className="countdown-time-container hours">
      <div className="countdown-time-value">{Math.abs(remainingHours)}</div>
      <div className="countdown-time-label calligraphy">Hours</div>
    </div>
    <div className="countdown-time-container mins">
      <div className="countdown-time-value">{Math.abs(remainingMins)}</div>
      <div className="countdown-time-label calligraphy">Mins</div>
    </div>
    <div className="countdown-time-container secs">
      <div className="countdown-time-value">{Math.abs(remainingSecs)}</div>
      <div className="countdown-time-label calligraphy">Secs</div>
    </div>
  </div>
}

export default Countdown
